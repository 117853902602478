import { DialogController }           from 'aurelia-dialog';
import { bindable, inject }           from 'aurelia-framework';
import { BaseFormViewModel }          from 'base-form-view-model';
import { CopyToAnotherLotFormSchema } from 'modules/management/concessions/lot-groups/copy-to-another-lot-modal/form-schema';
import { SpecificationsRepository }   from 'modules/management/specifications/specifications/services/repository';
import { AppContainer }               from 'resources/services/app-container';

@inject(AppContainer, SpecificationsRepository, DialogController, CopyToAnotherLotFormSchema)
export class CopyToAnotherLotModal extends BaseFormViewModel {

    formId = 'management.concessions.lot-groups.copy-to-another-lot-modal';

    @bindable alert = {};
    model           = {};
    schema          = {};
    create          = true;

    @bindable settings = {
        title:   'dialog.title.record-will-be-copied-to-lot',
        size:    'modal-md',
        buttons: true,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param dialogController
     * @param formSchema
     */
    constructor(appContainer, repository, dialogController, formSchema) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
        this.formSchema       = formSchema;
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.lot_intervention_id   = params.lot_intervention_id;
        this.lot_intervention_name = params.lot_intervention_name;

        this.bodyText = this.appContainer.i18n.tr('dialog.text.confirm-duplicate-record-to-lot', { lot_intervention: this.lot_intervention_name });

    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.dialogController.ok(this.lot_intervention_id);
    }

}
